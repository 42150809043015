import React, { useEffect } from "react";
import { navigate } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Loading from "../components/loading";

export default function() {
    return (
        <Layout lang="tc" i18nSEO="EPD">
            <SEO title="" />
            <IndexPage />
        </Layout>
    );
}

const getRedirectLanguage = () => {
    // default use traditional chinese
    let lang = "tc";
    if (navigator && navigator.language) {
        // check if browser language is english
        if (navigator.language.split("-")[0] === "en") {
            lang = "en";
        }
        // check if browser language is simplified chinese
        if (
            navigator.language.match(/-CN/g) !== null ||
            navigator.language.match(/-Hans/g) !== null
        ) {
            lang = "sc";
        }
    }
    return lang;
};

const IndexPage = () => {
    useEffect(() => {
        const urlLang = getRedirectLanguage();
        navigate(`/${urlLang}/`);
    }, []);

    return <Loading />;
};
